<template>
  <td :class="`${name}-cell`">
      <div
        ref="cellRef"
        v-tooltip="isTooltip && tooltip"
        :class="cellClasses"
      >
        <slot :name="`${name}-cell`" :value="value">
          <rcc-row-edit-button
            v-if="isEditAction"
            class="rcc-cell__content"
            @click="$emit('edit-action')"
          />

          <div v-else-if="icon">
            <v-icon small>
              {{ icon }}
            </v-icon>
          </div>

          <template v-else>
            <div class="rcc-cell__content">
              {{ realValue }}
            </div>
          </template>
        </slot>
      </div>
  </td>
</template>

<script>
import RccRowEditButton from './row-edit-button'

const BASE_CLASS = 'rcc-cell'

export default {
  name: 'RccCell',

  components: {
    RccRowEditButton
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Object, Number, Array],
      default: ''
    },

    modifiers: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isEditAction: false,
      isTooltip: false,
      tooltipCustomValue: '',
      cellClasses: [BASE_CLASS],
      icon: '',
      filler: null
    }
  },

  computed: {
    tooltip() {
      return this.tooltipCustomValue || this.realValue
    },

    realValue() {
      return this.isCustomCell ?
        this.value.value || (this.value.value === 0 && '0') || this.filler :
        (this.value && this.value.toString()) || (this.value === 0 && '0') || this.filler
    },

    isCustomCell() {
      return (this.value && typeof this.value === 'object' && this.value.type === 'customCell')
    }
  },

  mounted() {
    this.$nextTick(() => {
      for(const modifier of [...this.modifiers, ...this.value?.modifiers || []]) {
        this.$store.modifyCellFunctions[modifier.type](this, modifier)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
td {
  height: 30px !important;
  font-size: 13px !important;
  padding: 0 !important;
  border-bottom: thin solid rgba(0,0,0,.12);
}

.rcc-cell {
  min-height: 100%;
  display: flex;
  align-items: center;
  padding: $data-table-header-and-footer-paddings;

  &__tooltip {
    left: 0;
    overflow-wrap: break-word !important;
  }

  &_success {
    color: $success;
  }

  &_warning {
    color: $warning;
  }

  &_error {
    color: $error;
  }

  &_is-warning {
    background: $data-table-row-attention-bg;
  }

  &_center {
    div {
      text-align: center;
    }
  }

  i {
    color: currentColor;
  }

  &__content {
    &_pre-wrap {
      white-space: pre-wrap;
    }

    &_string-truncation {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .activator-wrapper {
    width: 100%;
  }
}
</style>
