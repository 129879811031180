<template>
  <div class="rcc-header-cell">
    {{ text }}
    <v-btn
      icon
      small
      :class="buttonClasses"
      @click="handleSortButtonClick"
    >
      <v-icon small>mdi-arrow-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
const STATES = [
  // null,
  'asc',
  'desc'
]

export default {
  name: 'RccHeaderSorter',

  props: {
    text: {
      type: String,
      default: ''
    },

    direction: {
      type: String,
      default: null
    }
  },

  computed: {
    buttonClasses() {
      return [
        'rcc-sort-button',
        this.direction && 'rcc-sort-button_' + this.direction
      ]
    },

    directionKey() {
      return STATES.findIndex(key => key === this.direction) || 0
    }
  },

  methods: {
    handleSortButtonClick() {
      const key = (this.directionKey + 1) % STATES.length

      this.$emit('sort', STATES[key])
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-header-cell {
  display: flex;
  align-items: center;
  gap: 5px;

  .rcc-sort-button {
    overflow: hidden;

    i {
      color: #00000035;
    }

    &_asc {
      i {
        color: $primary;
      }
    }

    &_desc {
      i {
        color: $primary;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
