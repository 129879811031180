<template>
  <div class="rcc-filters" v-if="filters.length > 0">
    <rcc-filter
      v-for="(filter, index) in filters"
      :key="index"
      :name="filter.name"
      :value="'' + filter.value"
      :text="filter.text"
      @close="$emit('remove', $event)"
    />
  </div>
</template>

<script>
import RccFilter from './rcc-filter.vue'

export default {
  name: 'RccFilters',

  components: { RccFilter },

  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    handleFilterClose() {
      this.$emit('remove', filters)
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-filters {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
</style>
