<template>
  <div class="rcc-pagination">
    <div v-if="pageCount > 1" class="rcc-pagination__wrapper">
      <v-pagination
        color="white"
        ref="paginationRef"
        :value="page"
        :length="pageCount"
        @input="$emit('update:page', $event)"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

const ACTIVE_BUTTON = document.createElement('div')

export default {
  name: 'RccPagination',

  props: {
    pageCount: {
      type: Number,
      default: 1
    },

    page: {
      type: Number,
      default: 1
    }
  },

  watch: {
    page(value) {
      this.setActive()
    }
  },

  mounted() {
    if (this.pageCount <= 1) {
      return
    }

    this.setActive(false)
    ACTIVE_BUTTON.classList.add('rcc-pagination__active')
    ACTIVE_BUTTON.addEventListener('transitionend', this.handleActiveButtonTransitionend)
    this.$refs.paginationRef.$el.append(ACTIVE_BUTTON)
  },

  methods: {
    async setActive(isMove = true) {
      if (this.pageCount <= 1) {
        return
      }

      await Vue.nextTick()

      const active = this.$refs.paginationRef.$el.querySelector('.v-pagination__item--active')
      const offset = active.offsetLeft
      const left = active.parentNode.offsetLeft + offset - 1

      ACTIVE_BUTTON.innerText = this.page
      ACTIVE_BUTTON.style.left = left + 'px'

      if (isMove) {
        ACTIVE_BUTTON.classList.add('rcc-pagination__move')
      }
    },

    handleActiveButtonTransitionend() {
      ACTIVE_BUTTON.classList.remove('rcc-pagination__move')
    }
  },

  destroyed() {
    ACTIVE_BUTTON.removeEventListener('transitionend', this.handleActiveButtonTransitionend)
  }
}
</script>

<style lang="scss">
$border-radius: 10px;

.rcc-pagination {
  position: relative;
  display: flex;
  justify-content: center;
  background: $data-table-secondary-bg;
  border-radius: 0 0 $base-border-radius $base-border-radius;
  padding: $data-table-header-and-footer-paddings;
  min-height: $data-table-header-and-footer-min-height;

  &__wrapper {
    min-width: 300px;
    max-width: 50%;
  }

  .v-pagination {
    &__item, &__navigation  {
      border-radius: $border-radius;
      box-shadow: none;
    }

    &__item--active {
      background: white !important;
      color: currentColor !important;
    }
  }

  &__active {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    top:50%;
    transform: translateY(-50%);
    left: 0;
    width: 36px;
    height: 36px;
    background: #8c90c1;
    z-index: 2;
    border-radius: $border-radius;
  }

  &__move {
    transition: all 0.25s ease-out 0s;
    transform: translateY(-50%) scale(1.2);
  }
}
</style>
