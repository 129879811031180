<template>
  <rcc-text-input
    v-model="searchString"
    :label="label"
    :width="width"
    :is-disabled="isDisabled"
    :type="type"
    hide-details
    @keyup-enter="handleSearchInput"
  >
    <template v-slot:append>
      <v-btn
        width="24"
        height="24"
        icon
        :disabled="isDisabled"
        @click="handleSearchInput"
      >
        <v-icon>{{ buttonIcon }}</v-icon>
      </v-btn>
    </template>
  </rcc-text-input>
</template>

<script>
import RccTextInput from './rcc-text-input'

export default {
  name: 'RccSearch',

  components: { RccTextInput },

  props: {
    label: {
      type: String,
      default: ''
    },

    width: {
      type: String,
      default: '300px'
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    buttonIcon: {
      type: String,
      default: 'mdi-magnify'
    },

    value: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'text'
    }
  },

  data() {
    return {
      searchString: ''
    }
  },

  watch: {
    value(value) {
      this.searchString = value
    }
  },

  mounted() {
    this.searchString = this.value
  },

  methods: {
    handleSearchInput() {
      this.$emit('input', this.searchString)
    }
  }
}
</script>
