<template>
  <div class="rcc-filter">
    {{ text }}
    <rcc-button
      :is-icon="true"
      size="x-small"
      @click="$emit('close', name)"
    >
      <v-icon small>mdi-close</v-icon>
    </rcc-button>
  </div>
</template>

<script>
import RccButton from 'Components/controls/rcc-button'

export default {
  name: 'RccFilter',

  components: { RccButton },

  props: {
    name: {
      type: String,
      default: 'unknown filter'
    },

    value: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-filter {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: default;
  border: 1px solid $primary;
  font-size: 12px;
  color: $primary;
  padding: 3px 10px;
  border-radius: $base-border-radius;
}
</style>
