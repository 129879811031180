<template>
  <div class="rcc-datepicker">
    <v-date-picker
      v-model="selectedDates"
      locale="ru"
      :range="isRange"
      selected-items-text="Диапазон дат"
    />
  </div>
</template>

<script>
export default {
  name: 'RccDatepicker',

  props: {
    value: {
      type: [String, Array],
      default: ''
    },

    isRange: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      rangeData: []
    }
  },

  computed: {
    selectedDates: {
      get() {
        return this.value
      },

      set(value) {
        const newValue = this.isRange && value.length === 2 ?
          [...value].sort((a, b) => this.$moment(a).unix() - this.$moment(b).unix()) :
          value

        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    resetData() {
      this.rangeData = []
    }
  }
}
</script>
